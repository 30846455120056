<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unreadNotificationsCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          v-if="unreadNotificationsCount > 0"
          pill
          variant="light-primary"
        >
          {{ unreadNotificationsCount }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.key"
        @click="clickedOnNotification(notification)"
      >
        <b-media
          :class="{'bg-light-primary': !notification.opened}"
        >
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.sender_details.profile_image_url"
              :variant="'primary'"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.content.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.content.message }}</small>
        </b-media>

      </b-link>

      <div
        class="view-all-notifications d-flex justify-content-center py-1 cursor-pointer"
        @click="goToNotificationsPage"
      >
        <h5 class="m-0 p-0 font-weight-bolder">
          View All Notifications
        </h5>
      </div>

      <!-- System Notification Toggler -->
      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div>

      <!-- System Notifications -->
      <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="readAllNotifications"
    >Read all notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import { notificationsMixins } from '@/views/notifications/mixins/notificationsMixins'
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },

  mixins: [notificationsMixins],

  data() {
    return {
      systemNotifications: [],

      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      }
    }
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

.view-all-notifications {
  &:hover, &:hover h5 {
    background: $primary;
    color: white;
  }
}

.notification-item:hover {
  // box-shadow:inset 0 0 0 99999px rgba(255,255,255,0.2);
  background: $primary;
}
</style>
