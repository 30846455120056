<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <div class="align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- Bookmarks Container -->
      <div
        v-if="$store.state.auth.ActiveUser && $store.state.auth.ActiveUser.email_address.includes('hhqmail')"
        class="rounded-lg bg-danger align-left"
        style="padding: 5px"
      >
        <h6
          class="text-white"
          style="margin-top: 7px"
        >
          This account is managed by EMPLOYMENT AND EMPLOYABILITY INSTITUTE PTE.
          LTD.
        </h6>
      </div>
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <cart-dropdown />
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
import CartDropdown from '@core/layouts/components/app-navbar/components/CartDropdown.vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import UserDropdown from '@core/layouts/components/app-navbar/components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    // DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
