/* eslint-disable import/prefer-default-export */

export const notificationsMixins = {
  data() {
    return {
      notifications: [],
      unreadNotificationsCount: 0,

      // server pagination
      previousFilters: {},
      filters: {},
      sorters: [],
      columns: ['_id', 'email', 'fullname', 'created_at', 'role', 'role_id', 'deactivated_at', 'account_approved',  'account_approved_text'],
      pagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 5,
      },

      serverParams: {},
      totalRecords: 0,
    }
  },
  watch: {
    '$route.name': function(newVal) {
      this.getNotifications()
    },

    'pagination.currentPage': function (val, oldVal) {
      this.getNotifications()
    },
    'pagination.perPage': function (val, oldVal) {
      this.getNotifications()
    },
  },
  created() {
    this.getNotifications()
  },
  methods: {
    getNotifications() {
      // server pagination
      let query = ''
      this.serverParams.page = this.pagination.currentPage
      this.serverParams.perPage = this.pagination.perPage
      query = `?params=${JSON.stringify(this.serverParams)}`

      this.$http
        .get(`/api/notifications${query}`)
        .then(response => {
          const { success, output, total_records } = response.data

          this.notifications = response.data.notifs
          this.totalRecords = total_records
          this.pagination.totalRows = total_records

          this.unreadNotificationsCount = 0
          this.notifications.forEach(notification => {
            if (!notification.opened) {
              this.unreadNotificationsCount += 1
            }
          })
        })
        .catch(() => {
          // this.$handleErrorResponse(error, 'An Error Occured', 'Could not retrieve notifications')
        })
    },

    readAllNotifications() {
      this.$http
        .put('/api/read-all-notifications', {})
        .then(response => {
          this.getNotifications()
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },

    clickedOnNotification(notification) {
      this.$http
        .put(`/api/read-notification/${notification.id}`, {})
        .then(response => {
          this.getNotifications()

          this.$router.push(notification.content.link)
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },

    goToNotificationsPage() {
      this.$router.push({ name: 'notifications' })
    },
  },
}